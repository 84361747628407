import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { SENTIMENT_COLORS } from '../../constants/sentimentColors';

ChartJS.register(ArcElement, Tooltip, Legend);

const SourcesPieChart = ({ stockData }) => {
    // Calculate sentiment counts and sources
    const sentimentData = {
        Positive: { count: 0, sources: new Set() },
        Neutral: { count: 0, sources: new Set() },
        Negative: { count: 0, sources: new Set() }
    };

    // Group sources by sentiment
    stockData.article_sentiments.forEach((sentiment, index) => {
        const source = new URL(stockData.article_urls[index]).hostname.replace('www.', '');
        sentimentData[sentiment].count++;
        sentimentData[sentiment].sources.add(source);
    });

    const total = stockData.article_sentiments.length;
    
    const data = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            data: [
                (sentimentData.Positive.count / total) * 100,
                (sentimentData.Neutral.count / total) * 100,
                (sentimentData.Negative.count / total) * 100
            ],
            backgroundColor: [
                SENTIMENT_COLORS.Positive.start,    // Green for positive
                SENTIMENT_COLORS.Neutral.start,     // Yellow for neutral
                SENTIMENT_COLORS.Negative.start     // Red for negative
            ],
            borderColor: [
                SENTIMENT_COLORS.Positive.end,
                SENTIMENT_COLORS.Neutral.end,
                SENTIMENT_COLORS.Negative.end
            ],
            borderWidth: 1
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 20,
                    usePointStyle: true
                }
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const sentiment = context.label;
                        const percentage = context.parsed.toFixed(1);
                        const sources = Array.from(sentimentData[sentiment].sources);
                        return [
                            `${sentiment}: ${percentage}%`,
                            'Sources:',
                            ...sources.map(source => `  • ${source}`)
                        ];
                    }
                }
            }
        }
    };

    return (
        <div className="bg-white rounded-lg p-6 shadow-lg h-[350px]">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Sentiment Distribution</h3>
            </div>
            <div className="h-64">
                <Pie data={data} options={options} />
            </div>
        </div>
    );
};

export default SourcesPieChart; 