import React, { useState } from 'react';

const NewsArticles = ({ stockData }) => {
    const [displayedArticles, setDisplayedArticles] = useState(5);

    const handleLoadMore = () => {
        setDisplayedArticles(prev => prev + 5);
    };

    const handleShowLess = () => {
        setDisplayedArticles(5);
    };

    return (
        <div className={`bg-white rounded-lg p-6 shadow-lg ${displayedArticles === 5 ? 'h-[350px] overflow-hidden' : ''}`}>
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Articles ({stockData.article_titles.length})</h3>
            </div>
            {stockData.article_titles.length > 0 ? (
                <>
                    <div className={`space-y-4 ${displayedArticles === 5 ? 'h-[250px] overflow-hidden' : ''}`}>
                        {stockData.article_titles.slice(0, displayedArticles).map((title, index) => (
                            <div key={index} className="border-b pb-2">
                                <a 
                                    href={stockData.article_urls[index]}
                                    className="text-blue-600 hover:text-blue-800 text-sm block whitespace-nowrap overflow-hidden text-ellipsis"
                                    title={title}
                                >
                                    {title}
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center">
                        {displayedArticles < stockData.article_titles.length ? (
                            <div className="-mt-6 mb-4">
                                <button 
                                    onClick={handleLoadMore}
                                    className="px-4 py-2 bg-emerald-500 text-white text-sm rounded hover:bg-emerald-600 transition-colors"
                                >
                                    Show More ↓
                                </button>
                            </div>
                        ) : displayedArticles > 5 && (
                            <div className="mt-6 mb-4">
                                <button 
                                    onClick={handleShowLess}
                                    className="px-4 py-2 bg-emerald-500 text-white text-sm rounded hover:bg-emerald-600 transition-colors"
                                >
                                    Show Less ↑
                                </button>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="h-64 flex items-center justify-center text-gray-500">
                    No articles available
                </div>
            )}
        </div>
    );
};

export default NewsArticles; 