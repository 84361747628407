import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import Navbar from './components/Layout/Navbar';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import StockList from './components/Stock/StockList';
import StockDetail from './components/Stock/StockDetail';
import StockDashboard from './components/Stock/Dashboard';
import { StockPriceProvider } from './context/StockPriceContext';
import { ClerkProvider } from '@clerk/clerk-react';

const AppContent = () => {
  const location = useLocation();
  
  const authRoutes = ['/login', '/register'];
  const shouldShowNavbar = !authRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <Navigate to="/dashboard" replace />
            </ProtectedRoute>
          } 
        />
        
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <StockDashboard />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/stocks"
          element={
            <ProtectedRoute>
              <StockList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stocks/:companyName"
          element={
            <ProtectedRoute>
              <StockDetail />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
};

function App() {
  
  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error('Missing Clerk Publishable Key');
  }

  return (
    <ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}>
      <AuthProvider>
        <StockPriceProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </StockPriceProvider>
      </AuthProvider>
    </ClerkProvider>
  );
}

export default App;