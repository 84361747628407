import React from 'react';

const DayPrices = ({ stockData }) => {
    const priceData = [
        { label: 'Day High', value: stockData.day_high },
        { label: 'Day Low', value: stockData.day_low },
        { label: 'Day Open', value: stockData.day_open },
        { label: 'Day Close', value: stockData.day_close },
    ];

    return (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mb-4 sm:mb-6">
            {priceData.map(({ label, value }) => (
                <div 
                    key={label} 
                    className="bg-white rounded-lg p-2 sm:p-3 shadow-lg transition-all duration-300 hover:shadow-xl"
                >
                    <div className="text-gray-500 text-sm sm:text-base">{label}</div>
                    <div className="font-bold text-base sm:text-lg truncate">
                        ₹{Number(value).toFixed(2)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DayPrices; 