import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { useAuth } from '../../context/AuthContext';
import bottom from '../assets/bottom.png';
import topleft from '../assets/topleft.png';
import graph from '../assets/Graph.png';
import topcenter from '../assets/topcenter.png';
import { useSignUp } from "@clerk/clerk-react";

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [showError, setShowError] = useState(true);
    const [verificationComplete, setVerificationComplete] = useState(false);
    const { setShowNavbar } = useAuth();
    const { isLoaded, signUp, setActive } = useSignUp();

    useEffect(() => {
        setShowNavbar(false);
        return () => setShowNavbar(true);
    }, [setShowNavbar]);

    const setErrorWithVisibility = (errorMessage) => {
        setError(errorMessage);
        setShowError(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!isVerifying && password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!isLoaded) {
            return;
        }

        try {
            if (!isVerifying) {
                // Initial sign-up
                await signUp.create({
                    emailAddress: username,
                    password: password
                });

                // Send the email verification code
                await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
                
                // Switch to verification mode
                setIsVerifying(true);
                setError('');
            } else {
                // Verify email
                const completeSignUp = await signUp.attemptEmailAddressVerification({
                    code: verificationCode,
                });
                
                if (completeSignUp.status === "complete") {
                    // Set the active session
                    await setActive({ session: completeSignUp.createdSessionId });
                    
                    // Register in your system
                    await authService.register(username, password);
                    
                    // Show success message instead of redirecting
                    setVerificationComplete(true);
                }
            }
        } catch (err) {
            setErrorWithVisibility(err.message || 'Registration failed');
        }
    };

    // Render success message if verification is complete
    if (verificationComplete) {
        return (
            <div className="h-screen bg-gray-100 flex flex-col items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4">
                    <div className="text-center">
                        <div className="mb-4 text-green-500">
                            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">
                            Verification Successful!
                        </h2>
                        <p className="text-gray-600 mb-8">
                            Your email has been verified successfully. You can now login to your account.
                        </p>
                        <Link 
                            to="/login"
                            className="w-full bg-emerald-500 text-white py-2 px-4 rounded-lg hover:bg-emerald-600 transition-colors inline-block"
                        >
                            Go to Login
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="h-screen bg-gray-100 flex flex-col">
            <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
                {/* Left Section - Hide on mobile */}
                <div className="hidden lg:flex lg:w-2/3 bg-gray-100 p-8 flex-col">
                    {/* Top Logo */}
                    <div className="mb-8 ml-16">
                        <div className="flex items-center gap-4">
                            <img 
                                src={topleft}
                                alt="Company Logo" 
                                className="h-8 w-8"
                            />
                        </div>
                    </div>

                    {/* Center Content */}
                    <div className="flex-grow flex flex-col justify-center items-center">
                        {/* Graph Image */}
                        <div className="w-full max-w-md mb-6">
                            <div className="relative h-80 w-full">
                                <img 
                                    src={graph}
                                    alt="Stock Graph" 
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        </div>
                        
                        <h1 className="text-3xl text-gray-800 font-bold text-left max-w-2xl mb-2 ml-24">
                            Build Your Bias with Confidence
                        </h1>
                        <p className="text-2xl text-gray-600 text-left max-w-md mb-12 mr-36">
                            Get a free account!
                        </p>
                    </div>
                </div>

                {/* Right Section - Full width on mobile */}
                <div className="w-full lg:w-1/3 bg-gray-100 flex flex-col h-full">
                    {/* Mobile Logo - Only show on mobile */}
                    <div className="flex lg:hidden justify-center mt-6 mb-4">
                        <img 
                            src={topcenter}
                            alt="Build A Bias Logo" 
                            className="h-16 w-auto"
                        />
                    </div>

                    {/* Top Navigation */}
                    <div className="flex justify-center items-center space-x-4 p-4 lg:p-8 bg-gray-100">
                        <button 
                            className="px-6 py-2 bg-[#00B386] text-white text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
                        >
                            Create Account
                        </button>
                        <Link 
                            to="/login"
                            className="text-sm text-emerald-500 border border-emerald-500 px-10 py-2 rounded-lg hover:bg-emerald-50 text-base bg-white"
                        >
                            Login
                        </Link>
                    </div>

                    {/* Form Section - Updated for mobile */}
                    <div className="flex-1 flex items-start justify-center px-4 lg:px-0 pb-4">
                        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-4 lg:p-6 mx-4 lg:mx-0">
                            <form onSubmit={handleSubmit} className="space-y-3 lg:space-y-4">
                                {error && showError && (
                                    <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                        <div className="flex justify-between items-start">
                                            <div className="flex-grow">{error}</div>
                                            <button 
                                                onClick={() => setShowError(false)}
                                                className="ml-4 text-red-400 hover:text-red-600"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                
                                {!isVerifying ? (
                                    // Registration Form
                                    <>
                                        <div>
                                            <label className="block text-sm font-bold text-black mb-2">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                                placeholder="Enter your email"
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-bold text-black mb-2">
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                                placeholder="Enter your password"
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-bold text-black mb-2">
                                                Re-Enter Password
                                            </label>
                                            <input
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                                placeholder="Confirm your password"
                                                required
                                            />
                                        </div>
                                    </>
                                ) : (
                                    // Verification Form
                                    <div>
                                        <label className="block text-sm font-bold text-black mb-2">
                                            Verification Code
                                        </label>
                                        <input
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                            placeholder="Enter verification code sent to your email"
                                            required
                                        />
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="w-full bg-emerald-500 text-white py-2 px-4 rounded-lg hover:bg-emerald-600 transition-colors"
                                >
                                    {isVerifying ? 'Verify Email' : 'Create Account'}
                                </button>

                                <div className="relative my-4">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"></div>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">or</span>
                                    </div>
                                </div>

                                <p className="text-center text-sm text-gray-600">
                                    Already have an account?{' '}
                                    <Link to="/login" className="text-emerald-500 hover:text-emerald-600">
                                        Login
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}
            <div className="w-full bg-white">
                <div className="container mx-auto px-4 py-3">
                    <div className="flex flex-row items-center gap-2 lg:gap-6 text-left">
                        <img 
                            src={bottom}
                            alt="Company Logo" 
                            className="h-8 lg:h-12 w-24 lg:w-32"
                        />
                        <p className="text-xs lg:text-sm text-gray-600">
                            <span className="lg:hidden">Stay ahead with a holistic view of stock sentiment.</span>
                            <span className="hidden lg:inline">
                              Stay ahead with a holistic view of stock sentiment. Access curated,<br/>
                              realtime news sentiment on top Indian stocks to make informed decisions.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;