import React from 'react';
import PriceChart from '../../Charts/PriceChart';
import SourcesPieChart from './SourcesPieChart';
import NewsArticles from './NewsArticles';

const DetailedAnalysis = ({ showDetails, setShowDetails, stockData }) => {
    return (
        <div className="mt-6">
            <button 
                onClick={() => setShowDetails(!showDetails)}
                className="flex items-center gap-2 py-4 text-left w-full"
            >
                <span className="font-bold text-base lg:text-lg">More Details</span>
                <svg 
                    className={`w-4 h-4 lg:w-5 lg:h-5 transform transition-transform ${showDetails ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            <div className={`transition-all duration-300 ease-in-out ${
                showDetails ? 'opacity-100 max-h-[2000px]' : 'opacity-0 max-h-0 overflow-hidden'
            }`}>
                {showDetails && (
                    <>
                        <hr className="border-t border-gray-200 mb-4 lg:mb-6" />
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
                            <div className="w-full min-h-[300px] lg:min-h-[400px]">
                                <PriceChart symbol={stockData.symbol} />
                            </div>
                            <div className="w-full min-h-[300px] lg:min-h-[400px]">
                                <SourcesPieChart stockData={stockData} />
                            </div>
                            <div className="w-full md:col-span-2 lg:col-span-1 min-h-[300px] lg:min-h-[400px]">
                                <NewsArticles stockData={stockData} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailedAnalysis; 