import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { useAuth } from '../../context/AuthContext';
import bottom from '../assets/bottom.png';
import topleft from '../assets/topleft.png';
import graph from '../assets/Graph.png';
import topcenter from '../assets/topcenter.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await authService.login(username, password);
      if (data.access_token && data.token_type) {
        login(data.access_token, username);
        navigate('/dashboard');
      } else {
        setError('Invalid response from server.');
      }
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      setTimeout(() => setError(''), 5000);
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex flex-col">
      <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
        {/* Left Section - Hide on mobile */}
        <div className="hidden lg:flex lg:w-2/3 bg-gray-100 p-8 flex-col">
          {/* Top Logo */}
          <div className="mb-8 ml-16">
            <div className="flex items-center gap-4">
              <img 
                src={topleft} 
                alt="Company Logo" 
                className="h-8 w-8"
              />
            </div>
          </div>

          {/* Center Content */}
          <div className="flex-grow flex flex-col justify-center items-center">
            {/* Graph Image */}
            <div className="w-full max-w-md mb-6">
              <div className="relative h-80 w-full">
                <img 
                  src={graph} 
                  alt="Stock Graph" 
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            
            <h1 className="text-3xl text-gray-800 font-bold text-left max-w-2xl mb-2 ml-24">
              Build Your Bias with Confidence
            </h1>
            <p className="text-2xl text-gray-600 text-left max-w-md mb-12 mr-40">
              Login to continue
            </p>
          </div>
        </div>

        {/* Right Section - Full width on mobile */}
        <div className="w-full lg:w-1/3 bg-gray-100 flex flex-col h-full">
          {/* Mobile Logo - Only show on mobile */}
          <div className="flex lg:hidden justify-center mt-6 mb-4">
            <img 
              src={topcenter}
              alt="Build A Bias Logo" 
              className="h-16 w-auto"
            />
          </div>

          {/* Top Navigation */}
          <div className="flex justify-center items-center space-x-4 p-4 lg:p-8 bg-gray-100">
            <Link 
              to="/register" 
              className="text-sm text-emerald-500 border border-emerald-500 px-6 py-2 rounded-lg hover:bg-emerald-50 text-base bg-white"
            >
              Create Account
            </Link>
            <button 
              className="px-10 py-2 bg-[#00B386] text-white text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
            >
              Login
            </button>
          </div>

          {/* Form Section - Updated for mobile */}
          <div className="flex-1 flex items-start justify-center px-4 lg:px-0 pb-4">
            <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-4 lg:p-6 mx-4 lg:mx-0">
              <form onSubmit={handleSubmit} className="space-y-3 lg:space-y-4">
                <div>
                  <label className="block text-sm font-bold text-black mb-2">
                    Email
                  </label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                    placeholder="Enter your email"
                  />
                </div>

                <div>
                  <label className="block text-sm font-bold text-black mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                    placeholder="Enter your password"
                  />
                </div>

                {error && (
                  <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                    {error}
                  </div>
                )}

                <div className="flex items-center justify-center">
                  <button 
                    type="button"
                    className="text-sm text-emerald-600 hover:text-emerald-500"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('Forgot password clicked');
                    }}
                  >
                    Forgot Password?
                  </button>
                </div>

                <button
                  type="submit"
                  className="w-full bg-emerald-500 text-white py-2 px-4 rounded-lg hover:bg-emerald-600 transition-colors"
                >
                  Login
                </button>

                <div className="relative my-4">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">or</span>
                  </div>
                </div>

                <p className="text-center text-sm text-gray-600">
                  Don't have an account?{' '}
                  <Link to="/register" className="text-emerald-500 hover:text-emerald-600">
                    Create one now!
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="w-full bg-white">
        <div className="container mx-auto px-4 py-3">
          <div className="flex flex-row items-center gap-2 lg:gap-6 text-left">
            <img 
              src={bottom}
              alt="Company Logo" 
              className="h-8 lg:h-12 w-24 lg:w-32"
            />
            <p className="text-xs lg:text-sm text-gray-600">
              <span className="lg:hidden">Stay ahead with a holistic view of stock sentiment.</span>
              <span className="hidden lg:inline">
                Stay ahead with a holistic view of stock sentiment. Access curated,<br/>
                realtime news sentiment on top Indian stocks to make informed decisions.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;