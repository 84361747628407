import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { stockService } from '../../services/stock.service';
import { useAuth } from '../../context/AuthContext';
import CompanyInfo from './components/CompanyInfo';
import SentimentOverview from './components/SentimentOverview';
import DetailedAnalysis from './components/DetailedAnalysis';
import bottom from '../assets/bottom.png';

const StockDetail = () => {
    const { companyName } = useParams();
    const [stockData, setStockData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { token } = useAuth();
    const [showDetails, setShowDetails] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState('24h');

    useEffect(() => {
        const fetchStockDetails = async () => {
            try {
                const data = await stockService.getStockByCompany(companyName, token);
                setStockData(data[0]);
            } catch (err) {
                setError('Failed to fetch stock details');
            } finally {
                setLoading(false);
            }
        };

        fetchStockDetails();
    }, [companyName, token]);

    if (loading) return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="flex flex-col items-center gap-4">
                <div className="w-12 h-12 border-4 border-[#00B386] border-t-transparent rounded-full animate-spin"></div>
                <p className="text-gray-600 font-medium">Loading stock details...</p>
            </div>
        </div>
    );

    if (error) return <div className="text-red-500 text-center p-4">{error}</div>;
    if (!stockData) return <div className="text-center p-4">No data found</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-between">
            <div className="container mx-auto p-4 lg:p-8">
                <div className="bg-white rounded-lg shadow p-4 lg:p-6">
                    <div className={`grid grid-cols-1 ${selectedPeriod === '24h' ? 'lg:grid-cols-4' : 'lg:grid-cols-1'} gap-4 lg:gap-6`}>
                        <CompanyInfo 
                            stockData={stockData} 
                            selectedPeriod={selectedPeriod}
                            onPeriodChange={setSelectedPeriod}
                        />
                        {selectedPeriod === '24h' && (
                            <SentimentOverview stockData={stockData} />
                        )}
                    </div>
                </div>
                
                {selectedPeriod === '24h' && (
                    <DetailedAnalysis 
                        showDetails={showDetails}
                        setShowDetails={setShowDetails}
                        stockData={stockData}
                    />
                )}
            </div>

            {/* Bottom Section */}
            <div className="w-full bg-white">
                <div className="container mx-auto px-4 py-3">
                    <div className="flex flex-row items-center gap-2 lg:gap-6 text-left">
                        <img 
                            src={bottom}
                            alt="Company Logo" 
                            className="h-8 lg:h-12 w-24 lg:w-32"
                        />
                        <p className="text-xs lg:text-sm text-gray-600">
                            <span className="lg:hidden">Stay ahead with a holistic view of stock sentiment.</span>
                            <span className="hidden lg:inline">
                                Stay ahead with a holistic view of stock sentiment. Access curated,<br/>
                                realtime news sentiment on top Indian stocks to make informed decisions.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockDetail;