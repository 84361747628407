import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { stockService } from '../../services/stock.service';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import bottom from '../assets/bottom.png';
import topcenter from '../assets/topcenter.png';
import SentimentBar from './components/SentimentBar';

const StockDashboard = () => {
  const { token } = useAuth();
  const [stocks, setStocks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [stockPrices, setStockPrices] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stocksData, userCompanies] = await Promise.all([
          stockService.getAllStocks(token),
          stockService.getUserCompanies(token)
        ]);
        
        setStocks(stocksData);
        
        // Fetch detailed data for each selected stock
        const selectedCompanies = userCompanies.companies;
        const detailedStocksPromises = selectedCompanies.map(company => 
          stockService.getStockByCompany(company, token)
        );
        
        const detailedStocksData = await Promise.all(detailedStocksPromises);
        const userStocks = detailedStocksData.map(data => data[0]); // Assuming getStockByCompany returns an array
        
        console.log("Detailed Selected Stocks:", userStocks);
        setSelectedStocks(userStocks);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = stocks.filter(stock =>
        stock.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStocks(filtered);
    } else {
      setFilteredStocks([]);
    }
  }, [searchTerm, stocks]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const pricePromises = selectedStocks.map(stock => 
          stockService.getStockPriceHistory(stock.symbol, token)
        );
        
        const pricesData = await Promise.all(pricePromises);
        
        const newPrices = {};
        selectedStocks.forEach((stock, index) => {
          const stockPriceData = pricesData[index];
          if (stockPriceData && stockPriceData.length > 0) {
            newPrices[stock.symbol] = stockPriceData[stockPriceData.length - 1].price;
          }
        });
        
        setStockPrices(newPrices);
      } catch (err) {
        console.error('Error fetching prices:', err);
      }
    };

    if (selectedStocks.length > 0) {
      fetchPrices();
      const interval = setInterval(fetchPrices, 60000); // Update every minute
      return () => clearInterval(interval);
    }
  }, [selectedStocks, token]);

  const handleSelectStock = async (stock) => {
    try {
      if (selectedStocks.length >= 5) {
        setError('You can only add up to 5 companies');
        setSearchTerm('');
        setFilteredStocks([]);
        setTimeout(() => setError(''), 10000);
        return;
      }

      if (selectedStocks.some(s => s.name === stock.name)) {
        setError('This stock is already in your list');
        setSearchTerm('');
        setFilteredStocks([]);
        setTimeout(() => setError(''), 10000);
        return;
      }

      await stockService.addCompany(stock.name, token);
      setSelectedStocks([...selectedStocks, stock]);
      setSearchTerm('');
      setFilteredStocks([]);
    } catch (err) {
      setError('Failed to add stock');
      setSearchTerm('');
      setFilteredStocks([]);
      setTimeout(() => setError(''), 10000);
    }
  };

  // const handleRemoveStock = async (stock) => {
  //   try {
  //     await stockService.removeCompany(stock.name, token);
  //     setSelectedStocks(selectedStocks.filter(s => s.name !== stock.name));
  //   } catch (err) {
  //     setError('Failed to remove stock');
  //   }
  // };

  const handleStockDetail = (stock) => {
    navigate(`/stocks/${stock.name}`);
  };

  const handleJoinWaitlist = () => {
    console.log('Joining waitlist...');
  };

  const calculateSentimentPercentages = (sentiments) => {
    const counts = {
      Positive: 0,
      Neutral: 0,
      Negative: 0
    };
    
    if (sentiments) {
      sentiments.forEach(sentiment => {
        if (sentiment in counts) {
          counts[sentiment] += 1;
        }
      });
    }
    
    const total = Object.values(counts).reduce((a, b) => a + b, 0);
    if (total === 0) return counts;
    
    return Object.fromEntries(
      Object.entries(counts).map(([k, v]) => [k, (v / total) * 100])
    );
  };

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="flex flex-col items-center gap-4">
        <div className="w-12 h-12 border-4 border-[#00B386] border-t-transparent rounded-full animate-spin"></div>
        <p className="text-gray-600 font-medium">Loading your stocks...</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen -pt-4 flex flex-col bg-gray-100">
      <main className="flex-1 max-w-3xl mx-auto w-full p-4">
        <div className="flex flex-col items-center">
          <img 
            src={topcenter}
            alt="Build A Bias Logo" 
            className="h-24 lg:h-36 w-auto mb-4"
          />
          <p className="font-bold text-black text-lg lg:text-xl mb-8 lg:mb-12 text-center px-4">
            Stock analysis and screening tool for investors in India
          </p>
        </div>

        <div className="relative mb-6 w-full mx-auto px-4">
          {selectedStocks.length >= 5 ? (
            <button
              onClick={handleJoinWaitlist}
              className="w-full px-4 sm:px-10 py-2 bg-[#00B386] text-white text-sm sm:text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
            >
              Join Waitlist to Track More Stocks
            </button>
          ) : (
            <>
              <Search className="absolute left-6 sm:left-8 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4 sm:w-5 sm:h-5" />
              <input
                type="text"
                placeholder="Search for a stock"
                className="w-full p-2 sm:p-3 pl-10 sm:pl-14 border rounded-lg shadow-sm text-sm sm:text-base"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {error && (
                <p className="text-red-500 text-xs sm:text-sm mt-2">{error}</p>
              )}
              {filteredStocks.length > 0 && (
                <div className="absolute z-10 bg-white border rounded-lg shadow-lg mt-1 w-full max-h-48 overflow-y-auto">
                  {filteredStocks.map((stock) => (
                    <div
                      key={stock.name}
                      className="p-2 sm:p-3 hover:bg-gray-100 cursor-pointer text-sm sm:text-base"
                      onClick={() => handleSelectStock(stock)}
                    >
                      {stock.name}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        <div className="bg-white rounded-lg shadow p-4 mx-4">
          <div className="mb-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h2 className="font-semibold text-base lg:text-lg">My Stocks</h2>
                <span className="ml-2 text-base lg:text-lg text-red-500">
                  ({selectedStocks.length}/5)
                </span>
              </div>
            </div>
          </div>
          
          {/* Mobile View */}
          <div className="lg:hidden">
            {selectedStocks.map((stock) => {
              const currentPrice = stockPrices[stock.symbol];
              const priceChange = currentPrice && stock.day_open
                ? ((currentPrice - stock.day_open) / stock.day_open) * 100
                : null;
              
              return (
                <div 
                  key={stock.name}
                  onClick={() => handleStockDetail(stock)}
                  className="border-b border-gray-200 py-2 lg:p-3 space-y-1.5 lg:space-y-2 hover:bg-gray-50 cursor-pointer"
                >
                  <div className="flex justify-between items-start">
                    <div className="font-medium text-sm lg:text-base">{stock.name}</div>
                    <div className="text-right">
                      {currentPrice 
                        ? (
                          <div>
                            <div className="text-sm lg:text-base">₹{currentPrice.toFixed(2)}</div>
                            <div className={`text-xs lg:text-sm ${
                              priceChange > 0 ? 'text-green-500' : 
                              priceChange < 0 ? 'text-red-500' : 
                              'text-gray-700'
                            }`}>
                              {priceChange !== null 
                                ? `${priceChange > 0 ? '+' : ''}${priceChange.toFixed(2)}%`
                                : '---'
                              }
                            </div>
                          </div>
                        )
                        : '---'
                      }
                    </div>
                  </div>
                  <div className="w-full h-4 lg:h-6 rounded-md overflow-hidden">
                    <SentimentBar 
                      sentiments={stock.article_sentiments}
                      calculateSentimentPercentages={calculateSentimentPercentages}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {/* Desktop View */}
          <div className="hidden lg:block">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-left font-semibold">Stock Name</th>
                  <th className="p-3 text-left font-semibold">Sentiment</th>
                  <th className="p-3 text-left font-semibold">Price</th>
                </tr>
              </thead>
              <tbody>
                {selectedStocks.map((stock) => {
                  const currentPrice = stockPrices[stock.symbol];
                  const priceChange = currentPrice && stock.day_open
                    ? ((currentPrice - stock.day_open) / stock.day_open) * 100
                    : null;
                  
                  return (
                    <tr 
                      key={stock.name} 
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleStockDetail(stock)}
                    >
                      <td className="p-3 border-b">{stock.name}</td>
                      <td className="p-3 border-b">
                        <div className="w-32 h-6 rounded-md overflow-hidden">
                          <SentimentBar 
                            sentiments={stock.article_sentiments}
                            calculateSentimentPercentages={calculateSentimentPercentages}
                          />
                        </div>
                      </td>
                      <td className="p-3 border-b">
                        {currentPrice 
                          ? (
                            <span>
                              ₹{currentPrice.toFixed(2)}{' '}
                              <span className={`${
                                priceChange > 0 ? 'text-green-500' : 
                                priceChange < 0 ? 'text-red-500' : 
                                'text-gray-700'
                              }`}>
                                ({priceChange !== null 
                                  ? `${priceChange > 0 ? '+' : ''}${priceChange.toFixed(2)}%`
                                  : '---'
                                })
                              </span>
                            </span>
                          )
                          : '---'
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>

      <div className="w-full bg-white">
        <div className="container mx-auto px-4 py-3">
          <div className="flex flex-row items-center gap-2 lg:gap-6 text-left">
            <img 
              src={bottom}
              alt="Company Logo" 
              className="h-8 lg:h-12 w-24 lg:w-32"
            />
            <p className="text-xs lg:text-sm text-gray-600">
              <span className="lg:hidden">Stay ahead with a holistic view of stock sentiment.</span>
              <span className="hidden lg:inline">
                Stay ahead with a holistic view of stock sentiment. Access curated,<br/>
                realtime news sentiment on top Indian stocks to make informed decisions.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockDashboard;