import React from 'react';
import DayPrices from './DayPrices';
import MovementReasons from './MovementReasons';

const CompanyInfo = ({ stockData, selectedPeriod, onPeriodChange }) => {
    const periods = [
        { label: '24hrs', value: '24h' },
        { label: '48hrs', value: '48h' },
        { label: '1 week', value: '1w' },
        { label: 'Custom', value: 'custom' }
    ];

    return (
        <div className="col-span-full lg:col-span-3 p-4 lg:p-0">
            {/* Company Header with Time Period Buttons */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-4 mb-4 lg:mb-6">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                    <h2 className="text-xl lg:text-2xl font-bold">{stockData.name}</h2>
                    <div className="flex items-center gap-2 sm:gap-4 text-sm lg:text-base">
                        <span className="text-gray-600">({stockData.symbol})</span>
                        <span className="text-gray-500">{stockData.exchange}</span>
                    </div>
                </div>
                
                <div className="flex gap-1 sm:gap-2">
                    {periods.map((period) => (
                        <button
                            key={period.value}
                            onClick={() => onPeriodChange(period.value)}
                            className={`px-2 sm:px-4 py-1 sm:py-1.5 rounded-full text-xs sm:text-sm font-medium transition-all whitespace-nowrap
                                ${selectedPeriod === period.value 
                                    ? 'bg-[#00B386] text-white' 
                                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                }`}
                        >
                            {period.label}
                        </button>
                    ))}
                </div>
            </div>
            
            {selectedPeriod === '24h' ? (
                <>
                    {/* Company Summary */}
                    <div className="text-sm lg:text-base text-gray-700 mb-4 lg:mb-6">
                        {stockData.overall_summary}
                    </div>

                    {/* Price and Movement Info */}
                    <div className="space-y-4 lg:space-y-6">
                        <DayPrices stockData={stockData} />
                        <MovementReasons stockData={stockData} />
                    </div>
                </>
            ) : (
                <div className="text-center py-8">
                    <p className="text-gray-600 mb-4">
                        Please join our waitlist to get sentiment insight for 
                        {selectedPeriod === '48h' ? ' last 48 hrs' : 
                         selectedPeriod === '1w' ? ' last 1 week' : 
                         ' custom timeline'}
                    </p>
                    <button
                        onClick={() => console.log('Join waitlist clicked')}
                        className="px-6 py-2 bg-[#00B386] text-white text-sm sm:text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
                    >
                        Join Waitlist
                    </button>
                </div>
            )}
        </div>
    );
};

export default CompanyInfo; 